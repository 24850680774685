import React from 'react'
import { graphql } from 'gatsby'
import { Grid, Container, makeStyles } from '@material-ui/core'
import Pager from '../components/pagerCategory'
import Master from '../components/Master'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Products from '../components/ListView/Products/Products'
import Categories from '../components/Categories';

export default ({ data, pageContext }) => {

  const useStyles = makeStyles(theme => ({
    content: {
      padding: theme.spacing(0, 3, 6),
    },
  }));

  const classes = useStyles();

  const category = data.category
  const products = data.category.products.slice(pageContext.skip, pageContext.skip + pageContext.limit)
  let empty = ''
  if (products.length === 0) {
    empty = <div>Nenhum produto foi encontrado.</div>
  }

  return (
    <Master title={category.title}>
      <GatsbySeo
        title={category.title}
        description={`Veja mais ${category.title} em ${process.env.SITE_NAME}`}
        canonical={`${process.env.SITE_URL}/categorias/${category.slug}`}
        noindex={products.length === 0 ? true : false}
        nofollow={products.length === 0 ? true : false}
        openGraph={{
          url: `${process.env.SITE_URL}/categorias/${category.slug}`,
          title: category.title,
          description: `Veja mais ${category.title} em ${process.env.SITE_NAME}`,
          images: [
            {
              url: `${process.env.SITE_IMAGE_OG}`,
              width: 512,
              height: 512,
              alt: category.title
            }
          ],
          site_name: `${process.env.SITE_NAME}`,
        }}
        twitter={{
          handle: '@handle',
          site: '@site',
          cardType: 'summary_large_image',
        }}
      />
      <Container maxWidth="md" component="main" className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h1>{category.title}</h1>
            <Categories align="left" />
            <Pager pageContext={pageContext} slug={category.slug} />
            <Products products={products} />
            <Pager pageContext={pageContext} slug={category.slug} />
            {empty}
          </Grid>
        </Grid>
      </Container>
    </Master>
  )
}

export const query = graphql`
  query($slug: String!) {
    category(slug: {eq: $slug}) {
      title
      slug
      products {
        id
        title
        slug
        price
        image
        imageThumbnail
      }
    }
  }
`

